// Bootstrap dropdown fix
$(document).on('click', '#login_wrapper .dropdown-menu li', function(e) {
    e.stopPropagation();
});

var mobile = $('body').hasClass('mobile');

$(function () {
    var $registerForm = $('#register_form form');
    $registerForm.submit(function(e) {
        e.preventDefault();
        if(validateForm($registerForm, 'register')) {
            sendForm($registerForm, 'register');
        }
        
        return false;
    });
    
    var $loginForm = $('#login_form form');
    $loginForm.submit(function(e) {
        e.preventDefault();
        
        if(validateForm($loginForm, 'login')) {
            sendForm($loginForm, 'login');
        }
        return false;
    });
    
    var $ownersLoginForm = $('#owners_login_form form');
    $ownersLoginForm.submit(function(e) {
        e.preventDefault();
        
        if(validateForm($ownersLoginForm, 'login')) {
            sendForm($ownersLoginForm, 'login');
        }
        return false;
    });
});

function validateForm($form, type) {
    removeErrors($form);
    
    var hasError = false;
    var $email = $form.find('input[name="email"]');

    if(!$email.val().length || !validateEmail($email.val())) {
        addError($email, Lang['signin_form_email_format']);
        hasError = true;
    }

    if(type === 'login' || type === 'register') {
        var $password = $form.find('input[name="password"]');
        
        if($password.val().length < 4) {
            addError($password, Lang['signin_form_pass_five_symbols']);
            hasError = true;
        }
    }

    if(type === 'register') {
        var $firstname = $form.find('input[name="firstname"]');
        var $lastname = $form.find('input[name="lastname"]');
        var $repeat = $form.find('input[name="repeat_password"]');
        var $agree = $('#agree');
        
        if($firstname.val().length < 1) {
            addError($firstname, Lang['signin_form_empty_field']);
            hasError = true;
        }
        
        if($lastname.val().length < 1) {
            addError($lastname, Lang['signin_form_empty_field']);
            hasError = true;
        }

        if($password.val() != $repeat.val()) {
            addError($password, Lang['signin_form_pass_match']);
            addError($repeat, Lang['signin_form_pass_match']);
            hasError = true;
        }
        
        if(!$agree.is(':checked')) {
            addError($agree);
            hasError = true;
        }
    } 

    if(hasError) {
        return false;
    } else {
        return true;
    }
}

function submitForm($form) {
    var email = $form.find('input[name="email"]').val();
    if(email.length > 0 && validateEmail(email)) {
        sendForm($form);
    } else {
        showAlert($form.find('.modal-body'), Lang['signin_form_email_empty_or_wrong']);
    }
}

function submitFormOwner($form) {
    var email = $form.find('input[name="email"]').val();
    if(email.length > 0 && validateEmail(email)) {
        sendForm($form);
    } else {
        showAlert($form.find('.modal-body'), Lang['signin_form_email_empty_or_wrong_owner']);
    }
}

function sendForm($form, type, scroll) {
    $.ajax({
        type: 'POST',
        dataType: 'JSON',
        data: $form.serialize(),
        url: $form.attr('action'),
        beforeSend: function() {
            hideAlert($form);
        },
        success: function(response) {
            if(response.fail !== undefined) {
                showAlert($form.find('.modal-body'), response.error);
            } else if(response.success !== undefined && response.success === 1) {
                if(response.redirect !== undefined) {
                    window.location = response.redirect;
                } else if(response.message !== undefined) {
                    $form.find('input[name="email"]').val('');
                    showAlert($form.find('.modal-body'), response.message, 'success');
                }
            }

            if(scroll != undefined && scroll == true) {
                $('#Modal').animate({
                    scrollTop: parseInt($form.offset().top)
                }, 2000);
            }
        },
        complete: function() {

        },
        error: function(jqXHR, textStatus, errorThrown) {
            console.log('Status: ' + textStatus);
            console.log('Error: ' + errorThrown);
        }
    });
}

function openModal(link, editform) {
    if(link !== undefined) {
        $.ajax({
            type: 'GET',
            dataType: 'html',
            url: link,
            beforeSend: function() {
                $('#Modal').remove();
            },
            success: function(html) {
                if(html) {
                    $('body').append(html);
                    var $modal = $('#Modal');
                    if($modal.length) {
                        $modal.modal('show');
                        
//                        if($modal.find('.price'))
//                            initPriceEvents();
                        
                        // edit forma
                        if(editform != undefined && editform == true)
                            initEditFormSubmit();
                    }
                }
            },
            error: function(jqXHR, textStatus, errorThrown) {
                console.log('Status: ' + textStatus);
                console.log('Error: ' + errorThrown);
            }
        });
    }
}

function redirectToLogin($el, type) {
    $.ajax({
        type: 'GET',
        dataType: 'JSON',
        url: '/authorize/getRedirectLink/' + type,
        beforeSend: function() {
            showProgressBar($el.closest('.popup-container'));
        },
        success: function(response) {
            if(response.success === 1 && response.redirect) {
                window.location = response.redirect;
            } else {
                notify(response.message);
            }
        },
        error: function(jqXHR, textStatus, errorThrown) {
            console.log('Status: ' + textStatus);
            console.log('Error: ' + errorThrown);
        }
    });
}         

function removeErrors($form) {
    $form.find('label.control-label').addClass('sr-only').removeClass('control-label');
    $form.find('.glyphicon').remove();
    $form.find('.form-group').removeClass('has-error has-feedback');
}
function addError($input, text) {
    var $formGroup = $input.closest('.form-group');
    $formGroup.addClass('has-error has-feedback');
    
    if(text !== undefined) {
        $input.after('<span class="glyphicon glyphicon-warning-sign form-control-feedback" aria-hidden="true"></span>');
        $formGroup.find('label').text(text).removeClass('sr-only').addClass('control-label');
    }
}
function validateEmail(email) {
    var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
}

function showProgressBar($el) {
    $el.prepend('<div class="progress"><div class="progress-bar progress-bar-warning progress-bar-striped active" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%">' + Lang['signin_please_wait'] + '</div></div>');
}
function hideProgressBar($el) {
    $el.find('.progress').remove();
}

function showAlert($el, message, type) {
    if(type === 'success') {
        $el.prepend('<div class="alert alert-success" role="alert"><span class="glyphicon glyphicon-ok" aria-hidden="true"></span> <span class="sr-only">' + message + '</span>' + message + '</div>');
    } else if(type === 'info') {
        $el.prepend('<div class="alert alert-info" role="alert"><span class="glyphicon glyphicon-info-sign" aria-hidden="true"></span> <span class="sr-only">' + message + '</span>' + message + '</div>');
    } else {
        $el.prepend('<div class="alert alert-danger" role="alert"><span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span> <span class="sr-only">' + message + '</span>' + message + '</div>');
    }
}
function hideAlert($el) {
    $el.find('.alert').remove();
}