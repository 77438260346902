var base_url = '/'; 
var dayNamesMin = ["Se", "Pr", "An", "Tr", "Ke", "Pe", "Še"];
var monthNames = ["Sausis", "Vasaris", "Kovas", "Balandis", "Gegužė", "Birželis", "Liepa", "Rugpjūtis", "Rugsėjis", "Spalis", "Lapkritis", "Gruodis"];
var datePickerPrefs = {
    dateFormat: "yy-mm-dd",
    dayNamesMin: dayNamesMin,
    changeYear: true,
    monthNames: monthNames,
    gotoCurrent: true,
    yearRange: "-100:+0"
};
    
$(document).ready(function() {
//    showThirdMenuLevel();
    equalMenuWidth();
    $( ".datepicker" ).datepicker(datePickerPrefs);
});

function openRegModal(){
    $('#loginModal').modal('hide');
    $('#regModal').modal('show');
}

function openLoginModal(){
    $('#regModal').modal('hide');
    $('#loginModal').modal('show');
}

$(window).load(function () {
//    equalheight('#categories_container .box');
});

$(window).resize(function () {
    equalMenuWidth();
//    equalheight('#categories_container .box');
});

equalheight = function (container) {
    var currentTallest = 0,
        currentRowStart = 0,
        rowDivs = new Array(),
        $el,
        topPosition = 0;

    $(container).each(function () {
        $el = $(this);
        $($el).height('auto')
        topPostion = $el.position().top;

        if (currentRowStart != topPostion) {
            for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                rowDivs[currentDiv].height(currentTallest);
            }
            rowDivs.length = 0; // empty the array
            currentRowStart = topPostion;
            currentTallest = $el.height();
            rowDivs.push($el);
        } else {
            rowDivs.push($el);
            currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
        }
        for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
            rowDivs[currentDiv].height(currentTallest);
        }
    });
}

equalMenuWidth = function () {
    var windowWidth = $(window).width();

    if(windowWidth > 768) {
        var $container = $('#Navbar .nav'),
            $elements = $('#Navbar .nav > li'),
            numberOfItems = $elements.length,
            // for evenly distributed li elements
            menuWidth = $container.outerWidth(),
            elementWidth = Math.round(menuWidth / numberOfItems).toFixed(2);
        
        $elements.css('width', elementWidth);
    }
}

// for third level of menu
function showThirdMenuLevel() {
    var $element = $('.nav-bar .has-flyout .flyout .has-flyout');
    
    if($element.length) {
        $element.hover(function() {
            $(this).find('ul.flyout').show();
        }, function() {
            $(this).find('ul.flyout').hide();
        });
    }
}

$(document).ready(function(){
    make_footer_not_so_ugly_and_crippled();
});

$(window).resize(function(){
   make_footer_not_so_ugly_and_crippled(); 
});

function make_footer_not_so_ugly_and_crippled(){
    var windowHeight = window.innerHeight;
    var header_height = $('#header_wrapper').outerHeight();
    var footer_height = $('#footer_wrapper').outerHeight();

    if($('.development_header').length > 0){
        header_height += $('.development_header').outerHeight();
    }

    var min_height_content = windowHeight - header_height - footer_height - 20;
    $('#content_wrapper').css({minHeight:min_height_content+'px'});
}

function log(data) {
    if (console && console.log) {
        console.log(data);
    }
}