 /*
* 
* It's fabulous :D
*/


$.fn.magicMap = function(fallback_text) {
    /* CLASSES */
   var DEVELOPMENT_MODE = false;
   var holder = this; // we are going to use it for event handling
   var element; // we are going to use it for event handling
   var canvas = null;
   var map = null;
   var baseWidth = 0;
   var canvasStyle = 'margin-top: 10px;margin-bottom: 20px;';
   var baseHeight= 0;
   var baseScale = 1;
   var firstLoad = true;
   var currentMap = null;
   var currentMapDataID = null;
   var currentObject = null;
   var currentSpotId = null;
   var disableInfoPopup = false;
   var width = 0; // current width
   var height = 0; // current height
   var transX = 0; // current shift for x axis
   var transY = 0; // current shift for y axis
   var scale = 1; // current global scale
   var loads = [];
   var still_loading = true;
   var settings = {
       current_state : 0,
       maps : []

   };
   var parkingPlan;

   var init = function(){
       $('#print_map_btn').click(function(){
           setScale(0,0,0);
           window.print();
       });
       
       $('#back_map_btn').click(function(){
           setDefaults();
           loadPlan();
       });
       
       setDefaults();
       if($('#map_type').val() === 'all'){
            loadPlan();
       }
       loadLots($('#map_type').val());
       
       initPopupConfirmation();
   };
   
   var loadPlan = function(){
       $('#back_map_btn').hide();
       hideGatePhones();
       loads.push('plan');
       parkingPlan = new ParkingUndergroundPlan(canvas,['a','b','c','d'],planLoadCallback,'plan');
   };
   
   var loadLots = function(type){
       $.getJSON( base_url + 'welcome/get_areas_json', function( data ) {
           settings.maps = data;
           if(type !== 'all'){
               currentMap = 4;
               currentMapDataID = 4;
               loadMap(4);
           }
       });
   };
   
   var setDefaults = function(){
       baseWidth = 0;
       baseHeight= 0;
       baseScale = 1;
       width = 0; // current width
       height = 0; // current height
       transX = 0; // current shift for x axis
       transY = 0; // current shift for y axis
       scale = 1; // current global scale
       if(firstLoad){
           firstLoad = !firstLoad;
       }
       holder.find('.canvas-container').remove();
       holder.append('<canvas class="canvas" width="' + (holder.width()) + '" height="500" style="' + canvasStyle +'">' + fallback_text + '</canvas>');
       element = holder.find('.canvas');
       canvas = new fabric.Canvas(element.get(0), {
                                       selection: false, // disable groups selection
                                       scale: 1, // set default scale
                                       renderOnAddRemove: false, // disable auto-render in order to improve performance for complex maps with a lot of markers
                                       moveCursor: 'move', // reset mouse cursor - they are not used by us
                                       hoverCursor: 'pointer'
                                   });
   };
   
   var planLoadCallback = function(callName){
       var $self = this;
       $self.callName = callName;
       if(DEVELOPMENT_MODE){
            console.log('Main callback: ' + $self.callName);
       }
       loads = loads.filter(function(e){return e!==$self.callName;});
       if(loads.length === 0){
            if(DEVELOPMENT_MODE){
                console.log('Main : All Data Loaded');
            }
            var curBaseScale;
            still_loading = false;
            baseWidth = parkingPlan.width;
            baseHeight = parkingPlan.height;
            width = element.closest('.map_holder').width();
            height = element.height();
            ratio = baseHeight / baseWidth;
            curBaseScale  = baseScale;
            baseScale = width / baseWidth;
            scale *= baseScale / curBaseScale;

            canvas.setWidth(width);
            canvas.setHeight(width * ratio);
            applyTransform();
            resizePlanEverything();
            $(window).bind('resize', resizePlanEverything);
       }
       
   };
   
   var loadMap = function(data_id){
       fabric.util.loadImage(base_url + settings.maps[data_id].map, function(img) {
           map = new fabric.Image(img);
           var curBaseScale;
           if(data_id == '4'){
                $('#back_map_btn').hide();
                if (('ontouchstart' in window) || (window.DocumentTouch && document instanceof DocumentTouch)) {
                    bindContainerTouchEvents();
                } else {
                    bindContainerEvents();
                }
            }else{
                $('#back_map_btn').show();
            }
            
            showGatePhones(settings.maps[data_id].gate_phones);

           // Set base and current dimensions
           baseWidth = map.width;
           baseHeight = map.height;
           width = element.closest('.map_holder').width();
           height = element.height();
           ratio = baseHeight / baseWidth;

           // Disable all edit and object selecting functionality on the canvas
           map.set({
                hasRotatingPoint: false,
                hasBorders: false,
                hasControls: false,
                lockScalingY: true,
                lockScalingX: true,
                lockMovementX: true,
                renderOnAddRemove: false,
                lockMovementY: true,
                selectable: false,
                left: map.width / 2,
                top: map.height / 2,
                originX: 'center',
                originY: 'center'
           });
           canvas.add(map);

           // Zoom after load in order to show whole map from the beginning
           curBaseScale  = baseScale;
           baseScale = width / baseWidth;
           scale *= baseScale / curBaseScale;

           canvas.setWidth(width);
           canvas.setHeight(width * ratio);
           //resizeEverything();
           addSpotsLayer();
           applyTransform();
           $(window).bind('resize', resizeEverything);
       });
    };
   
    var addSpotsLayer = function() {
       fabric.loadSVGFromURL(base_url + settings.maps[currentMapDataID].overlay, function (object) {
           group = new fabric.Group(object,{
               hasRotatingPoint: false,
               hasBorders: false,
               hasControls: false,
               lockScalingY: true,
               lockScalingX: true,
               lockMovementX: true,
               renderOnAddRemove: false,
               lockMovementY: true,
               selectable: true,
               originX: "center",
               originY: "center",
               lockUniScaling: true,
               lockRotation: true,
               scaleX: scale,
               scaleY: scale
           });

           group.left = group.left * scale;
           group.top = group.top * scale;
           object = group.getObjects();
           for(var i = 0; i < object.length;i++){
               object[i].scaleX = scale;
               object[i].scaleY = scale;
               object[i].lockMovementX = true;
               object[i].lockMovementY = true;
               object[i].perPixelTargetFind = true;
               object[i].targetFindTolerance = 4;
               object[i].hasBorders = false;
               object[i].top = group.top + object[i].top * scale;
               object[i].left = group.left + object[i].left * scale;
               if(object[i].id != 'back_numbers'){
                    if(settings.maps[currentMapDataID].spots[object[i].id] !== undefined){
                        if(settings.maps[currentMapDataID].spots[object[i].id].available == "0"){
                            object[i].fill = "#cccccc";
                            object[i].selectable = false;
                        }else if(settings.maps[currentMapDataID].spots[object[i].id].visible == "0"){
                            object[i].fill = "#ff0000";
                            object[i].bg = "#ff0000";
                            object[i].selectable = true;
                            object[i].available = 0;
                            object[i].on('formAsk',loadAskForm);
                            object[i].on('showPopup',showInfoPopup);
                            object[i].hover = "#b20000";
                        }else if(settings.maps[currentMapDataID].spots[object[i].id].occupied == "1"){
                            object[i].fill = "#ff0000";
                            object[i].bg = "#ff0000";
                            object[i].selectable = true;
                            object[i].available = 0;
                            object[i].on('formAsk',loadAskForm);
                            object[i].on('showPopup',showInfoPopup);
                            object[i].hover = "#b20000";
                        }else{
                            object[i].fill = "#00a200";
                            object[i].bg = "#00a200";
                            object[i].available = 1;
                            object[i].on('formOpen',loadRegistrationForm);
                            object[i].on('showPopup',showInfoPopup);
                            object[i].hover = "#007100";
                        }
                    }else{
                        object[i].fill = "#cccccc";
                        object[i].selectable = false;
                    }
               }
               canvas.add(object[i]);
           }
            canvas.on('mouse:down', function(elem) {
               if(elem !== undefined){
                   if(elem.target !== undefined){
                       if(elem.target.__eventListeners !== undefined){
                           if(elem.target.__eventListeners.formOpen !== undefined){
                               elem.target.__eventListeners.formOpen[0](elem.target);
                           }
                           if(elem.target.__eventListeners.formAsk !== undefined){
                               elem.target.__eventListeners.formAsk[0](elem);
                           }
                       }
                   }
               }
           });
           
           canvas.on('mouse:move', function(elem) {
               if(elem !== undefined){
                   if(elem.target !== undefined){
                       if(elem.target.__eventListeners !== undefined){
                           if(elem.target.__eventListeners.showPopup !== undefined){
                               elem.target.__eventListeners.showPopup[0](elem, elem.target.available);
                           }
                       } else {
                           hideInfoPopup(elem.target);
                       }
                   }
               }
           });
           
            canvas.on('mouse:over', function(elem) {
                if(elem.target.hover !== undefined){
                     var spot = elem.target;
                     spot.set('fill', spot.hover);
                     canvas.renderAll();
                }
            });
            canvas.on('mouse:out', function(elem) {
                if(elem.target.bg !== undefined){
                     var spot = elem.target;
                     spot.set('fill', spot.bg);
                     canvas.renderAll();
                }
            });
           
          applyTransform();
          canvas.renderAll();
       });   
    };
   
    var recalculateReservationFormSum = function(form){
       var price_pricelist_item = form.find('input[type="radio"]:checked').attr('data-amount');
       var price_pricelist_item_type = form.find('input[type="radio"]:checked').attr('data-type');
       var deposit = form.find('#deposit').attr('data-amount');
       var duration_pricelist_item = form.find('input[type="radio"]:checked').attr('data-duration');
       var price_additional = form.find('input[name="additional_price"]').val();
       var additional_selected_taxes = $("input[name^=additional_tax]:checked");
       var additional_selected_taxes_amount = 0;
       
       if(additional_selected_taxes.length > 0){
           for(var i = 0; i < additional_selected_taxes.length; i++){
               additional_selected_taxes_amount += Number($(additional_selected_taxes[i]).attr('data-amount'));
           }
       }
       
       var total_month = Number(price_pricelist_item) + Number(price_additional) + Number(additional_selected_taxes_amount);
       var total = Number(price_pricelist_item * duration_pricelist_item) + Number(additional_selected_taxes_amount * duration_pricelist_item) + Number(price_additional * duration_pricelist_item) + Number(deposit);
       
       if(form.find('input[type="radio"]:checked').length > 0){
            form.find('#total_reservation_month_sum').text(total_month.toFixed(2));
            
            if(price_pricelist_item_type == 'single'){
                form.find('#total_reservation_sum').closest('.row').show();
                form.find('#total_reservation_sum').text(total.toFixed(2));
            }else{
                form.find('#total_reservation_sum').closest('.row').hide();
            }
        }
    };
    
    var sendReservationData = function(data){
       $response = $.post(base_url+'reservation/process_data', data,function(){
            try {
                var responseJSON = JSON.parse($response.responseText);
                if(responseJSON.status === "success"){
                    window.location = base_url + 'reservation/cart';
                }else if(responseJSON.status === "error" && responseJSON.value === "need_login"){
                    $('#signInDropdown').trigger('click');
                }else{
                    $('#registrationModal').find('.error_holder').text(errorsData[responseJSON.value]).show().delay(3000).fadeOut(500);
                }
           }catch(e){
               console.log(e);
           }
       });
    };
   
    var checkReservationForm = function(form){
       var price_checked = form.find('input[type="radio"]').is(':checked');
       if(!price_checked){
           form.find('.error_holder').text(errorsData.nothing_selected).show().delay(3000).fadeOut(500);
           return false;
       }
       return true;
    };
   
   var collectReservationForm = function(form){
       var areaId = form.find('input[name="area_id"]').val();
       var spotId = form.find('input[name="spot_id"]').val();
       var priceListItem = form.find('input[type="radio"]:checked').val();
       var additionalTaxes = $("input[name^=additional_tax]:checked").serializeArray();
       return {
           area_id : areaId,
           spot_id : spotId,
           plist_item_id : priceListItem,
           additionals : additionalTaxes
       };

    };
   
    var applyTransform = function () {
       var maxTransX,
           maxTransY,
           minTransX,
           minTransY,
           group;

       // Calculation of thresholds for x axis transition
       if (baseWidth * scale <= width) {
               // Map fits into the canvas
               maxTransX = (width - baseWidth * scale) / (2 * scale);
               minTransX = (width - baseWidth * scale) / (2 * scale);
       } else {
               // Map doesnt fit
               maxTransX = 0;
               minTransX = (width - baseWidth * scale) / scale;
       }

       // Limit transition with calculated thresholds
       if (transX > maxTransX) {
               transX = maxTransX;
       } else if (transX < minTransX) {
               transX = minTransX;
       }

       // The same for y axis
       if (baseHeight * scale <= height) {
               maxTransY = (height - baseHeight * scale) / (2 * scale);
               minTransY = (height - baseHeight * scale) / (2 * scale);
       } else {
               maxTransY = 0;
               minTransY = (height - baseHeight * scale) / scale;
       }
       if (transY > maxTransY) {
               transY = maxTransY;
       } else if (transY < minTransY) {
               transY = minTransY;
       }

       // Group all objects and apply transform on the group
       group = new fabric.Group(canvas.getObjects());
       group.scaleX = scale / canvas.scale;
       group.scaleY = scale / canvas.scale;
       group.left = transX * scale;
       group.top = transY * scale;
       group.destroy();

       // Refresh global scale for the canvas
       canvas.scale = scale;
       // At last render canvas with changed objects
       canvas.renderAll();
    };
   
    var setScale = function (scaleToSet, anchorX, anchorY) {
       var zoomMax = 1.5, // max 5x zoom
           zoomMin =  1, // min 1x zoom - real image size
           zoomStep; // required scale change

       // Limit scale if needed
       if (scaleToSet > zoomMax * baseScale) {
               scaleToSet = zoomMax * baseScale;
       } else if (scaleToSet < zoomMin * baseScale) {
               scaleToSet = zoomMin * baseScale;
       }

       // The center of zoom - a point which should stay on its place.
       // It is defined by anchorX and anchorY arguments.
       // In fact, it is just a position of mouse pointer during scaling.
       if (typeof anchorX !== 'undefined' && typeof anchorY !== 'undefined') {
               zoomStep = scaleToSet / scale;
               // Calculate required shift for all objects
               // so the center of zoom stays motionless.
               removeX = (zoomStep - 1) / scaleToSet * (canvas.width / 2);
               removeY = (zoomStep - 1) / scaleToSet * (canvas.height / 2);
               transX -= removeX;
               transY -= removeY;
       }
       
       if(scaleToSet == baseScale){
           // TODO(minde) : set to center(Lyg ir veikia nesu tikras)
       }

       scale = scaleToSet;	
       applyTransform();
    };
   
    var bindContainerEvents= function () {
       var mouseDown = false,
               oldPageX,
               oldPageY,
               container = $(canvas.wrapperEl);

       container.mousemove(function (e) {
               // Shifting
               if (mouseDown) {
                       // Calculate transition with respect to the current scale
                       transX -= (oldPageX - e.pageX) / scale;
                       transY -= (oldPageY - e.pageY) / scale;

                       applyTransform();

                       oldPageX = e.pageX;
                       oldPageY = e.pageY;
                       return false;
               }
       }).mousedown(function (e) {
               // Store position
               mouseDown = true;
               oldPageX = e.pageX;
               oldPageY = e.pageY;
               return false;
       });

       $('body').mouseup(function () {
               mouseDown = false;
       });

       // Zoom with mouse wheel
       container.mousewheel(function (event, delta, deltaX, deltaY) {
               var offset = element.offset(), // position of the canvas on the page
                       centerX = event.pageX - offset.left, // x coordinate of the center of zoom 
                       centerY = event.pageY - offset.top, // y coordinate of the center of zoom 
                       zoomStep = Math.pow(1.3, deltaY); // user-friendly zoom step

               setScale(scale * zoomStep, centerX, centerY);

               // Prevent scroll of the page
               event.preventDefault();
       });
    };
   
    var bindContainerTouchEvents = function () {
       var touchStartScale;
       var touchStartDistance;
       var container = $(canvas.wrapperEl);
       var touchX;
       var touchY;
       var centerTouchX;
       var centerTouchY;
       var lastTouchesLength;
       var handleTouchEvent = function (e) {
                       var touches = e.originalEvent.touches,
                               offset,
                               currentScale,
                               transXOld,
                               transYOld;

                       if (e.type === 'touchstart') {
                               lastTouchesLength = 0;
                       }
                       if (touches.length === 1) {
                               // Simple shift
                               if (lastTouchesLength === 1) {
                                       transXOld = transX;
                                       transYOld = transY;
                                       transX -= (touchX - touches[0].pageX) / scale;
                                       transY -= (touchY - touches[0].pageY) / scale;
                                       applyTransform();
                                       if (transXOld !== transX || transYOld !== transY) {
                                               e.preventDefault();
                                       }
                               }
                               touchX = touches[0].pageX;
                               touchY = touches[0].pageY;
                       } else if (touches.length === 2) {
                               // Zoom
                               if (lastTouchesLength === 2) {
                                       currentScale = Math.sqrt(
                                         Math.pow(touches[0].pageX - touches[1].pageX, 2) +
                                         Math.pow(touches[0].pageY - touches[1].pageY, 2)
                                       ) / touchStartDistance;
                                       setScale(touchStartScale * currentScale, centerTouchX, centerTouchY);
                                       e.preventDefault();
                               } else {
                                       // This is zoom start, store current state
                                       offset = element.offset();
                                       if (touches[0].pageX > touches[1].pageX) {
                                               centerTouchX = touches[1].pageX + (touches[0].pageX - touches[1].pageX) / 2;
                                       } else {
                                               centerTouchX = touches[0].pageX + (touches[1].pageX - touches[0].pageX) / 2;
                                       }
                                       if (touches[0].pageY > touches[1].pageY) {
                                               centerTouchY = touches[1].pageY + (touches[0].pageY - touches[1].pageY) / 2;
                                       } else {
                                               centerTouchY = touches[0].pageY + (touches[1].pageY - touches[0].pageY) / 2;
                                       }
                                       centerTouchX -= offset.left;
                                       centerTouchY -= offset.top;
                                       touchStartScale = scale;
                                       touchStartDistance = Math.sqrt(
                                         Math.pow(touches[0].pageX - touches[1].pageX, 2) +
                                         Math.pow(touches[0].pageY - touches[1].pageY, 2)
                                       );
                               }
                       }

                       lastTouchesLength = touches.length;
               };
       container.bind('touchstart', handleTouchEvent);
       container.bind('touchmove', handleTouchEvent);
    };
    
    var registrationFormLoad = function(){
        $('#registrationModal').modal('show');

        $('#registrationModal #reserve_btn').click(function(){
            if(checkReservationForm($('#registrationModal'))){
                var data = collectReservationForm($('#registrationModal'));
                sendReservationData(data);
            }
        });

        $('#registrationModal input[name="additional_phone"]').keypress(function(e){
            if(checkReservationForm($('#registrationModal'))){
                recalculateReservationFormSum($('#registrationModal'));
            }
            if (e.which !== 8 && e.which !== 0 && (e.which < 48 || e.which > 57)) {
                return false;
            }
        });

        $('#registrationModal input[name="additional_phone"]').blur(function(e){
             recalculateReservationFormSum($('#registrationModal'));
        });
        
        $("input[name^=additional_tax]").change(function(){
            recalculateReservationFormSum($('#registrationModal'));
        });

        $('#registrationModal tr').each(function(){
            $(this).bind('click',function(elem){
                $(elem.currentTarget).find('input[type="radio"]').prop("checked", true);
                recalculateReservationFormSum($('#registrationModal'));
            });
        });
    };
    
    var loadRegistrationForm = function(elem) {
        hideInfoPopup();
        hideConfirmation();
        $('#registrationModal').load(base_url + 'reservation/get_reservation_form/' + (currentMapDataID + 1) + '/' + elem.id, registrationFormLoad);
    };
    
    var loadAskForm = function(e) {
        hideInfoPopup();
        if($('#signInDropdown').length) {
            $('#signInDropdown').trigger('click');
        } else {
            showPopupConfirmation(e);
        }
    };

    var showPopupConfirmation = function(e) {
        var elem = e.target;
        var pointer = canvas.getPointer(e.e);

        currentSpotId = elem.id;

        showConfirmation();
        
        $('.popover.confirmation').css({
            position: 'absolute',
            top: pointer.y,
            left: pointer.x + 40
        });
        
        $('.popover.confirmation .arrow').css({
            top: '50%'
        });
    }
    
    var initPopupConfirmation = function() {
        $('#confirm-popup').confirmation({
            onConfirm: function() {
                disableInfoPopup = false;
                
                confirmAskForm((currentMapDataID + 1), currentSpotId);
            },
            onCancel: function() {
                disableInfoPopup = false;
            }
        });
    }

    var showConfirmation = function() {
        // kad nerodytu popover ant hover
        disableInfoPopup = true;
        $('#confirm-popup').confirmation('show');
    }

    var hideConfirmation = function() {
        disableInfoPopup = false;
        $('#confirm-popup').confirmation('hide');
    }
    
    var confirmAskForm = function(area_id, spot_number) {
       $response = $.post(base_url+'reservation/add_ask_data', {'area_id': area_id, 'spot_number': spot_number}, function() {
            try {
                var responseJSON = JSON.parse($response.responseText);
                if(responseJSON.status === "success") {
                   showNotify(Lang.success_newsletter_text, 'success');
                } else {
                    showNotify(Lang.error_newsletter_text, 'danger');
                }
            } catch(e) {
                console.log(e);
            }
       });
    }

    var showNotify = function(message, type) {
        $.notify({
            // options
            message: message
        },{
            // settings
            type: type,
        });
    }
   
    var showInfoPopup = function(e) {
        if(!disableInfoPopup) {
            var elem = e.target;
            var pointer = canvas.getPointer(e.e);

            if(elem.available !== undefined) {
                $('.mapPopover').css({
                    position: 'absolute',
                    top: pointer.y + 30,
                    left: pointer.x + 30
                });

                // patikriname current, jeigu current, tai tada galima nekeisti
                if(currentObject == null || currentObject.id != elem.id) {
                    if(elem.available == 1) {
                        $('#greenPopover').popover('show');
                        $('#redPopover').popover('hide');
                    } else if(elem.available == 0) {
                        $('#redPopover').popover('show');
                        $('#greenPopover').popover('hide');
                    }

                    currentObject = elem;
                }
            }
        }
    }
    
    var hideInfoPopup = function(elem) {
        currentObject = null;
        
        if(elem !== undefined) {
            setTimeout(function(){ 
                if(currentObject == null) {
                    $('.mapPopover').popover('hide');
                }
            }, 500);
        } else {
            $('.mapPopover').popover('hide');
        }
    }
    
   var showGatePhones = function(phones) {
       $('#map-phone-holder').html(phones);
   }
   
   var hideGatePhones = function() {
       $('#map-phone-holder').html('');
   }
   
   var resizeEverything = function(){
       baseWidth = map.width;
       baseHeight = map.height;
       width = element.closest('.map_holder').width();
       height = element.height();
       ratio = baseHeight/baseWidth;

       curBaseScale  = baseScale;
       baseScale = width / baseWidth;
       scale *= baseScale / curBaseScale;

       canvas.setWidth(width);
       canvas.setHeight(width * ratio);

       applyTransform();
   };
   
    var resizePlanEverything = function(){
       baseWidth = parkingPlan.width;
       baseHeight = parkingPlan.height;
       width = element.closest('.map_holder').width();
       height = element.height();
       ratio = baseHeight/baseWidth;

       curBaseScale  = baseScale;
       baseScale = width / baseWidth;
       scale *= baseScale / curBaseScale;

       canvas.setWidth(width);
       canvas.setHeight(width * ratio);

       applyTransform();
   };
   
   function ParkingUndergroundPlan(canvas, names, loadCallback, callName){
        var planSelf = this;
        planSelf.planCanvas = canvas;
        planSelf.zones = [];
        planSelf.background = null;
        planSelf.overlay = null;
        planSelf.SVGData = [];
        planSelf.width = 0;
        planSelf.height = 0;
        planSelf.last_id = '';
        planSelf.callName = callName;
        planSelf.zoneNames = names;
        planSelf.zoneLoads = names.slice(0);
        planSelf.loads = ['background','zonesSVG','zones','overlay'];
        

        planSelf.init = function(){
            planSelf.loadBackground();
            
            planSelf.planCanvas.on('mouse:move', function(e) {
                if(e.target !== undefined){
                    if(e.target.id !== undefined){
                        var result = e.target.id.split('_')[0];
                        if(planSelf.last_id !== result){
                            planSelf.last_id = result;
                            if(e.target.id.indexOf('main_back') !== -1 || e.target.id.indexOf('button') !== -1){
                                var zone_id = planSelf.zoneNames.indexOf(result);
                                if(zone_id !== -1){
                                    planSelf.zones[zone_id].mouseOver();
                                    for(var i = 0; i < planSelf.zones.length; i++){
                                        if(i != zone_id){
                                            planSelf.zones[i].mouseOut(false);
                                        }
                                    }
                                }
                                if(DEVELOPMENT_MODE){
                                    console.log('mouse on: ' + e.target.id);
                                }
                            }else{
                                for(var j = 0; j < planSelf.zones.length; j++){
                                        planSelf.zones[j].mouseOut(true);
                                }
                            }
                        }
                    }else{
                        planSelf.last_id = '';
                        for(var k = 0; k < planSelf.zones.length; k++){
                                planSelf.zones[k].mouseOut(true);
                        }
                    }
                }else{
                    planSelf.last_id = '';
                    for(var l = 0; l < planSelf.zones.length; l++){
                            planSelf.zones[l].mouseOut(true);
                    }
                }
            });
              
            planSelf.planCanvas.on('mouse:down', function(e) {
                if(e.target !== undefined){
                if(e.target.id !== undefined){
                      var result = e.target.id.split('_')[0];
                      var zone_id = planSelf.zoneNames.indexOf(result);
                      if(zone_id !== -1){
                          setDefaults();
                          loadMap(zone_id);
                          currentMap = zone_id;
                          currentMapDataID = zone_id;
                      }
                      if(DEVELOPMENT_MODE){
                          console.log('click: ' + e.target.id);
                      }
                  }
                }
            });
        };
        
        planSelf.loadBackground = function(){
            planSelf.background = new ParkingStaticImage(planSelf.planCanvas,false, 'resources/parking_plans/main_back.png',planSelf.callback,'background');
        };
        
        planSelf.loadOverlay = function(){
            planSelf.overlay = new ParkingStaticImage(planSelf.planCanvas,false, 'resources/parking_plans/main_numbers.png',planSelf.callback,'overlay');
        };
        
        planSelf.loadZones = function(){
            for(var i = 0; i < planSelf.zoneNames.length; i++){
                planSelf.zones[i] = new ParkingZone(canvas,planSelf.zoneNames[i],planSelf.zonesCallback);
            }
         };
         
         planSelf.loadZonesSVG = function(){
             fabric.loadSVGFromURL(base_url + 'resources/parking_plans/main_zone_highlights.svg', function (object) {
                group = new fabric.Group(object);
                group.set({
                    hasRotatingPoint: false,
                    hasBorders: false,
                    hasControls: false,
                    lockScalingY: true,
                    lockScalingX: true,
                    lockMovementX: true,
                    lockMovementY: true,
                    selectable: true,
                    originX: "center",
                    originY: "center",
                    lockRotation: true,
                    scaleX: scale,
                    scaleY: scale
                });

                object = group.getObjects();
                for(var i = 0; i < object.length;i++){
//                    
                    object[i].lockMovementX = true;
                    object[i].lockMovementY = true;
                    object[i].active = true;
                    object[i].perPixelTargetFind = true;
                    object[i].targetFindTolerance = 4;
                    object[i].selectable= true;
                    object[i].hasBorders = false;
                    object[i].top = (group.top + object[i].top * scale);
                    object[i].left = (group.left + object[i].left * scale);
                    planSelf.planCanvas.add(object[i]);
                    if(object[i].id !== undefined){
                        if(object[i].id.indexOf('main_back') !== -1 || object[i].id.indexOf('hover_back') !== -1){
                            object[i].opacity = 0.88;
                        }
                    }
                    planSelf.SVGData.push(object[i]);
                }
                planSelf.zonesSVGCallback();
            });
         };
        
        planSelf.draw = function(){
            planSelf.overlay.draw();
        };
        
        planSelf.callback = function(callName){
            planSelf.loads = planSelf.loads.filter(function(e){return e!==callName;});
            
            /* AFTER BACKGROUND LOAD OVERLAY*/
            if(DEVELOPMENT_MODE){
                console.log('Plan class: ' + callName);
            }
            
            if(callName === 'background'){
                planSelf.loadZonesSVG();
            }else if(callName === 'zones'){
                planSelf.loadOverlay();
            }
            
            
            if(planSelf.loads.length === 0){
                /* SETS PLAN WIDTH AND HEIGHT */
                planSelf.width = planSelf.background.width;
                planSelf.height = planSelf.background.height;
                /* CALLS CALLBACK IF ITS FUNCTION */
                if(typeof loadCallback === 'function'){
                    loadCallback(planSelf.callName);
                }
            }
        };
        
        planSelf.zonesSVGCallback = function(){
            planSelf.callback('zonesSVG');
            planSelf.loadZones();
        };
        
        planSelf.zonesCallback = function(callName){
            planSelf.zoneLoads = planSelf.zoneLoads.filter(function(e){return e!==callName;});
            
            if(DEVELOPMENT_MODE){
                console.log('Plan class: ' + callName);
            }
            
            if(planSelf.zoneLoads.length === 0){
                planSelf.callback('zones');
            }
        };
        
        return planSelf.init();
    }

    function ParkingStaticImage(canvas, selectable, file ,loadCallback, callName){
        var imageSelf = this;
        imageSelf.planCanvas = canvas;
        imageSelf.fileName = '';
        imageSelf.fileDir = '';
        imageSelf.image = null;
        imageSelf.width = 0;
        imageSelf.height = 0;
        imageSelf.callName = callName;

        imageSelf.init = function(filename){
            imageSelf.fileName = filename;
            imageSelf.fileDir = base_url + filename;
            imageSelf.load();
        };

        imageSelf.load = function(){
            try{
                if(imageSelf.fileName === ''){
                    throw 2;
                }
                
                fabric.Image.fromURL(imageSelf.fileDir,function(img){
                    imageSelf.image = img;
                    imageSelf.width = imageSelf.image.width;
                    imageSelf.height = imageSelf.image.height;
                    imageSelf.image.hasRotatingPoint = false;
                    imageSelf.image.hasBorders = false;
                    imageSelf.image.hasControls = false;
                    imageSelf.image.lockScalingY = true;
                    imageSelf.image.lockScalingX = true;
                    imageSelf.image.perPixelTargetFind = true;
                    imageSelf.image.targetFindTolerance = 4;
                    imageSelf.image.lockMovementX = true;
                    imageSelf.image.lockMovementY = true;
                    imageSelf.image.selectable = selectable;
                    imageSelf.image.originX = 'center';
                    imageSelf.image.originY = 'center';
                    
                    imageSelf.planCanvas.add(imageSelf.image);
                    imageSelf.callback(imageSelf.callName);
                });
            }catch(e){
                if(e === 2){
                    if(DEVELOPMENT_MODE){
                        console.error("Coudn't load image : empty filename");
                    }
                    imageSelf.callback(imageSelf.callName);
                }else{
                    if(DEVELOPMENT_MODE){
                        console.error("Coudn't load image : \"" + imageSelf.fileDir + "\"");
                    }
                    imageSelf.callback(imageSelf.callName);
                }
            }
        };

        imageSelf.draw = function(){
            imageSelf.planCanvas.renderAll();
        };
        
        imageSelf.callback = function(callName){
            if(typeof loadCallback === 'function'){
                if(DEVELOPMENT_MODE){
                    console.log('Image class: ' + imageSelf.callName);
                }
                loadCallback(imageSelf.callName);
            }
        };
        
        return imageSelf.init(file);
    }

    function ParkingZone(canvas, name, loadCallback){
        var zoneSelf = this;
        zoneSelf.zoneName = '';
        zoneSelf.planCanvas = canvas;
        zoneSelf.bigButton = null;
        zoneSelf.bigButtonDefaultColor = '';
        zoneSelf.bigButtonInactiveColor = '#ccc';
        zoneSelf.main = [];
        zoneSelf.hover = [];
        zoneSelf.loads = ['bigButton', 'main','hover'];

        zoneSelf.init = function(zone_name){
            zoneSelf.zoneName = zone_name;
        };
        
        zoneSelf.mouseOver = function(){
             for(var i = 0; i < zoneSelf.main.length; i++){
                zoneSelf.main[i].visible = false;
                zoneSelf.main[i].selectable = false;
            }
            
            for(i = 0; i < zoneSelf.hover.length; i++){
                if(zoneSelf.hover[i].id.indexOf('hover_back') !== -1){
                    zoneSelf.hover[i].visible = true;
                    zoneSelf.hover[i].selectable = true;
                }else{
                    zoneSelf.hover[i].visible = true;
                    zoneSelf.hover[i].selectable = true;
                }
            }
            
            zoneSelf.bigButton.fill = zoneSelf.bigButtonDefaultColor;
            zoneSelf.planCanvas.renderAll();
        };
        
        
        zoneSelf.mouseOut = function(useDefaultColor){
            for(var i = 0; i < zoneSelf.main.length; i++){
                if(zoneSelf.main[i].id.indexOf('main_back') !== -1){
                    zoneSelf.main[i].visible = true;
                    zoneSelf.main[i].selectable = true;
                }else{
                    zoneSelf.main[i].visible = true;
                    zoneSelf.main[i].selectable = true;
                }
            }
            
            for(i = 0; i < zoneSelf.hover.length; i++){
                zoneSelf.hover[i].visible = false;
                zoneSelf.hover[i].selectable = false;
            }
            
            if(useDefaultColor){
                zoneSelf.bigButton.fill = zoneSelf.bigButtonDefaultColor;
            }else{
                zoneSelf.bigButton.fill = zoneSelf.bigButtonInactiveColor;
            }
            zoneSelf.planCanvas.renderAll();
        };
        
        zoneSelf.show = function(){
            for(var i = 0; i < zoneSelf.main.length; i++){
                if(zoneSelf.main[i].id.indexOf('main_back') !== -1){
                    zoneSelf.main[i].visible = true;
                    zoneSelf.main[i].selectable = true;
                }else{
                    zoneSelf.main[i].visible = true;
                    zoneSelf.main[i].selectable = true;
                }
            }
            
            for(i = 0; i < zoneSelf.hover.length; i++){
                if(zoneSelf.hover[i].id.indexOf('hover_back') !== -1){
                    zoneSelf.hover[i].visible = true;
                    zoneSelf.hover[i].selectable = true;
                }else{
                    zoneSelf.hover[i].visible = true;
                    zoneSelf.hover[i].selectable = true;
                }
            }
            zoneSelf.planCanvas.renderAll();
        };
        
        zoneSelf.hide = function(){
            for(var i = 0; i < zoneSelf.main.length; i++){
                zoneSelf.main[i].visible = false;
                zoneSelf.main[i].selectable = false;
            }
            
            for(i = 0; i < zoneSelf.hover.length; i++){
                zoneSelf.hover[i].visible = false;
                zoneSelf.hover[i].selectable = false;
            }
            zoneSelf.planCanvas.renderAll();
        };

        zoneSelf.init = function(zone_name){
            var canvas_objects = canvas.getObjects();
            zoneSelf.zoneName = zone_name;
            for(var i = 0; i < canvas_objects.length; i++){
                if(canvas_objects[i].id !== undefined){
                    if(canvas_objects[i].id.indexOf(zone_name + '_') === 0){
                        if(canvas_objects[i].id.indexOf('main') !== -1){
                            zoneSelf.main.push(canvas_objects[i]);
                        }
                        if(canvas_objects[i].id.indexOf('hover') !== -1){
                            zoneSelf.hover.push(canvas_objects[i]);
                        }
                        if(canvas_objects[i].id.indexOf('button_back') !== -1){
                            zoneSelf.bigButtonDefaultColor = canvas_objects[i].fill.slice(0);
                            zoneSelf.bigButton = canvas_objects[i];
                        }
                    }
                }
            }
            zoneSelf.callback();
        };
        
        zoneSelf.callback = function(){
            if(typeof loadCallback === 'function'){
                if(DEVELOPMENT_MODE){
                    console.log('Zone class : ' + zoneSelf.zoneName + ' loaded');
                }
                loadCallback(zoneSelf.zoneName);
            }
        };

        return zoneSelf.init(name);
    }

   return this.each(function() {
       init();
   });
};